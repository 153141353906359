// Preload images in a section that's configured to use image_transitioner.
export function preload(featureElm) {
  // Don't do this if <link rel="preload" imgsrcset> is not supported by the browser.
  if (!('imageSrcset' in document.createElement('link'))) {
    return
  }

  let useWebp = document.documentElement.classList.contains('webp')
  let featureElmData = featureElm.dataset
  let preloads = []
  let prefixes = [
    'mainImage',
    'overlayImage1',
    'overlayImage2',
    'overlayImage3',
  ]

  prefixes.forEach((prefix) => {
    if (featureElmData[prefix + 'Src']) {
      preloads.push([
        (useWebp ? featureElmData[prefix + 'SrcWebp'] : null) ||
          featureElmData[prefix + 'Src'],
        (useWebp ? featureElmData[prefix + 'SrcsetWebp'] : null) ||
          featureElmData[prefix + 'Srcset'],
        featureElmData[prefix + 'Sizes'],
      ])
    }
  })

  preloads.forEach((data) => {
    let [src, srcset, sizes] = data
    if (document.querySelector(`link[rel='preload'][href='${src}']`)) {
      return
    }

    let link = document.createElement('link')
    link.rel = 'preload'
    link.as = 'image'
    link.href = src
    link.imageSrcset = srcset
    if (sizes) {
      link.imageSizes = sizes
    }
    document.head.appendChild(link)
  })
}
