/*
  Preload images for some dynamic slideshowy type things. Only needed when <link rel="preload" imagesrcset> is not
  supported - Safari is the only major browser to not support it (as of 16.5).
  https://caniuse.com/mdn-html_elements_link_imagesrcset

  This solution is worse because it does not take into account responsive sizes.
 */
if (!('imageSrcset' in document.createElement('link'))) {
  document.addEventListener('DOMContentLoaded', () => {
    let useWebp = document.documentElement.classList.contains('webp')
    let attrSuffixesToCopy = useWebp ? 'SrcWebp' : 'Src'
    document
      .querySelectorAll(
        '.simplero-new-feature_toggler__feature, .simplero-new-feature-with-images__images .image-transitioner'
      )
      .forEach((e) => {
        for (let attrName in e.dataset) {
          if (attrName.endsWith(attrSuffixesToCopy)) {
            let attrValue = e.dataset[attrName]
            const img = new Image()
            img.src = attrValue
          }
        }
      })
  })
}
